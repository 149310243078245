$primary: #ec008c !default;
$secondary: #662D91 !default;
$dark: #292929 !default;
$light: #fde1f1 !default;
$lightgray: #f5f5f5;

$theme-1: $primary !default;
$theme-2: $secondary !default;
$theme-3: #00A1E4 !default;
$theme-4: #0054A6 !default;
$theme-5: #FFC300 !default;
$theme-6: #009A4E !default;
$theme-7: #3CC682 !default;
$theme-8: #E25797 !default;

$theme-1-light: $light !default;
$theme-2-light: #d2c8da !default;
$theme-3-light: #def2fa !default;
$theme-4-light: #deedfc !default;
$theme-5-light: #fff7dd !default;
$theme-6-light: #e4fcee !default;

$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3,
        'theme-4': $theme-4,
        'theme-5': $theme-5,
        'theme-6': $theme-6,
        'theme-1-light': $theme-1-light,
        'theme-2-light': $theme-2-light,
        'theme-3-light': $theme-3-light,
        'theme-4-light': $theme-4-light,
        'theme-5-light': $theme-5-light,
        'theme-6-light': $theme-6-light
);
$light-accent: lighten($theme-2, 30%);

//========================
// general theme options
//========================
$footer-header-color: $secondary !default;
$card-link-color: $primary !default;
$link-decoration: underline;
$border-radius: 10px; // radius for cards, images and buttons
$line-height-base: 1.6;

//========================
//define text properties
//========================
@import url("https://use.typekit.net/bzf2kvb.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-base: "poppins", sans-serif;
$headings-font-weight: 600;
$display-font-weight: 600;
$font-weight-bold: 600;
$headings-color: $theme-1;

//========================
//define button properties
//========================
$btn-font-weight: 300;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-footer-style: theme-5;
$account-button-style: secondary;
$booking-button-style: 'success';
$order-button-style: 'primary';

//========================
//define banner spots properties
//========================
$accordion-banner-bg-color: theme-1; // Ect: theme-1, primary, white
$accordion-banner-text-color: white;

//========================

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

@import '../../../../src/scss/main.scss';

$display-font-weight: 600 !default;
$opacity-3: 0.3;

@import '@/src/scss/components/hero-video.scss';
@import '@/src/scss/components/whatsapp.scss';

@import '@/src/scss/components/banner-timeline.scss';

* {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: clip;
}

h1,.h1 {
  font-family: "blanket", sans-serif;
  font-weight: 900;
  font-size: 4.5rem;
  line-height: 0.85;
}
h2,.h2 {
  font-family: "blanket", sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 1;
  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
    line-height: 0.8;
  }
}

.welcome {
  @extend .h2;
}

.h2 {
  font-size: 2.5rem;
}

.h4, h4 {
  @extend .text-dark;
}

.text-star {
  color: $theme-5 !important;
}

.btn-theme-5 {
  @extend .text-dark;
}

.dotted {
  border-top: dotted 9px;
}

.social--icons {
  padding: 8px 4px 2px 5px;
  border-radius: 50px;
  margin-bottom: 8px;
  @extend .bg-theme-3, .mr-1;
  i {
    font-size: 1.3rem;
    color: $white;
  }
}

.quick-navigation--banner {
  @extend .pl-0;
  border-bottom: dotted 9px lightgray;

  a {
    text-decoration: none !important
  }
}

.rounded-side-img {
  border-radius: $border-radius 0 0 $border-radius;
  object-fit: cover;
}

.row--quickbanner {
  margin-top: -9rem;
  @include media-breakpoint-up(lg) {
    margin-top: -13rem;
  }
}

.soft-shadow, .account--button {
  @include media-breakpoint-up(lg) {
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
    .nav-link {
      font-size: 0.9rem;
      margin-top: 2px;
    }
  }
}

.hero-banner {
  @include media-breakpoint-up(lg) {
    margin-top: -85px;
  }

  .flavour {
    height: 475px;
    width: 490px;
    &.flavour-general {
      height: 420px !important;
      width: 440px !important;
    }
    .overflow-hidden {
      img {
        border-radius: 240px !important;
        padding-top: 53px;
        @extend .h-100;
        @extend .w-100;
      }
    }
  }
  .vimeo-player {
    min-height: 33vh !important;
    min-width: 60vh !important;
  }
  .vimeo-wrapper {
    border-radius: 240px !important;
    height: 332px !important;
    margin-top: 100px !important;
    padding-top: 53px;
    @extend .w-100;
  }
}

.cards-bannerspot--btn {
  @extend .btn-outline-theme-3-light;
  @extend .btn-block;
  @extend .text-dark;
  font-weight: 400;
}

.mobile--aspect-img {
  @include media-breakpoint-down(sm) {
    .flavour-stripe {
      width: 50%;
    }
    .card-img-top {
      aspect-ratio: 2.1;
      object-fit: cover;
    }
  }
}

.read-more {
  @include media-breakpoint-up(md) {
    position: absolute !important;
    bottom: 13px;
    right: 22px;
  }
}

.hero-flavour {
  height: 230px;
  @include media-breakpoint-down(md) {
    height: 80px;
  }
}

.flavour--img {
  img {
    max-height: 120px;
    @include media-breakpoint-up(md) {
      max-height: 170px;
    }
  }
}

.flavour--img-2 {
  img {
    max-height: 120px;
    @include media-breakpoint-up(md) {
      max-height: 220px;
    }
  }
}

.opacity-3 {
  opacity: $opacity-3;
}

.flavour-bg--img {
  img {
    opacity: $opacity-3;
    max-height: 280px;
    @include media-breakpoint-up(md) {
      max-height: 330px;
    }
  }
}

.rounded-lg {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-radius: $border-radius $border-radius 0 0 !important;
}

.btn {
  &.btn--footer {
    @extend .btn-#{$btn-footer-style}
  }
}

.banner-max {
  object-fit: cover;
  min-height: 750px;
  max-height: 750px;
  @include media-breakpoint-down(md) {
    min-height: 300px;
    max-height: 500px;
  }
}
.banner-general {
  object-fit: cover;
  min-height: 650px;
  max-height: 650px;
  @include media-breakpoint-down(md) {
    min-height: 400px;
    max-height: 500px;
  }
}

.section--recommended {
  @include media-breakpoint-down(md) {
    background: none !important;
  }
}


.navbar--menu {
  .navbar-collapse {
    @include media-breakpoint-down(md) {
      .nav-item {
        margin-left: 0 !important;
      }
      background-color: $theme-1;
      margin-top: 1rem;
      .nav-link {
        color: white !important;
      }
    }
  }

  .nav-item {
    .dropdown-menu {
      @extend .border-secondary;

      a {
        @extend .text-primary;
      }

      @include media-breakpoint-down(md) {
        background: none !important;
        a {
          color: white !important;
        }
      }
    }
  }
}

.navbar-brand {
  @extend .my-auto;
}

.navbar-brand--img {
  height: 40px;
  @include media-breakpoint-up(lg) {
    height: 60px;
  }
}

.float--bar {
  @extend .top-0;
  @extend .bg-white;
  @extend .position-absolute;
  right: -900px !important;
  width: 1000px !important;
}

.nav-link, .topbar-card--link {
  i {
    @extend .mx-1;
  }
}

.dropdown-submenu {
  .dropdown-menu {
    @extend .p-0;
    border-radius: 0 !important;
  }
}

.topbar-card--link {
  .badge {
    @extend .float-right;
    @extend .badge-success;
    @extend .text-white;
  }
}

.footer-menu--item {
  font-weight: 400 !important;
}


.card-title {
  @extend .mb-2;
}

.form-label {
  font-weight: 500;
}

.card--item {
  .info-bar--background {
    @extend .text-theme-2;
    @extend .pb-0;
    @extend .mb-0;
    @extend .pt-3;
  }

  .card-body {
    @extend .pt-2;
  }
}

.accordion {
  .card-header {
    @extend .p-1;
  }
  @for $i from 1 through 6 {
    .text-theme-#{$i} {
      &::after {
        @extend .text-theme-#{$i};
      }
    }
  }
}

.hero-video--thumb {
  @extend .w-100;
}

.top-menu {
  @extend .py-lg-2;

  a {
    @extend .text-white;
    @extend .text-decoration-none;
  }
}

.navbar-nav, .header--mobile-items {
  a {
    @extend .text-dark;

    &:hover {
      @extend .text-theme-1;
    }
  }
}

.content-wrapper {
  @extend .mt-3;
}

.main-headerless {
  @extend .pt-4;
}



.header--border {
  @extend .border;
  @extend .border-light;
}

.wrapper {
  @extend .mt-3;
}

.container--menu {
  @extend .my-3;
  @extend .p-0;
  @extend .my-lg-0;
  @extend .justify-content-end;

  .navbar--menu {
    @extend .p-0;
    @extend .pb-lg-2;
  }

  .shoppingcart--icon {
    @extend .text-decoration-none;
    @extend .mr-2;
    @extend .text-white;
    @include media-breakpoint-down(md) {
      position: relative;
      .badge {
        position: absolute;
        right: -13px;
        top: -11px;
      }
    }

    i {
      font-size: 1em;
    }
  }
  .nav-search {
    .nav-link {
      @extend .px-1;
      @extend .text-white;

      i {
        font-size: 1em;
      }
    }
  }
}

.highlight--cta {
  height: 300px;
  @include media-breakpoint-up(md) {
    height: 360px;
  }
}

#rooster, #Rooster, .rooster {
  .thead-light {
    th {
      background: none !important;
      border-bottom: solid 2px $theme-1 !important;
      color: $theme-1 !important;
    }
  }
  tbody {
    th {
      font-weight: 500;
    }
  }

  th {
    font-weight: 400;
  }
  h4, h3 {
    font-size: 2.5rem;
    @extend h2;
    @extend .text-dark;
  }
}

.title--page {
  @extend .d-none;
}

.flavor-shape {
  @extend .w-100;
  max-height: 140px;
  aspect-ratio: 8.1;
  margin-bottom: -1px;
  margin-top: -1px;
}

.agenda--home {
  h2 {
    @extend .text-center;
    @extend .mb-4;
  }
}

.card-stretch-hover {
  .card-img-top {
    transition: transform .3s;
    @extend .w-100;
    @extend .h-auto;
  }

  .card-img-bg {
    object-fit: cover;
    transition: transform .3s;
    @extend .h-100;
    @extend .w-100;
  }

  &:hover {
    .card-img-top, .card-img-bg {
      transform: scale(1.1);
    }
  }
}

.display-5 {
  font-weight: $display-font-weight !important;
}

.hyphens--auto {
  hyphens: auto;
}

.mascot-img {
  height: 270px;
  width: 150px;
  right: -75px;
}

.bg-gradient-theme-1, .bg-gradient-theme-8 {
  background: linear-gradient(270deg, $theme-8 10%, $theme-1 90%);
  &:hover {
    filter: brightness(0.9);
  }
}
.bg-gradient-theme-3, .bg-gradient-theme-4 {
  background: linear-gradient(270deg, $theme-3 10%, $theme-4 90%);
  &:hover {
    filter: brightness(0.9);
  }
}
.bg-gradient-theme-6, .bg-gradient-theme-7 {
  background: linear-gradient(270deg, $theme-7 10%, $theme-6 90%);
  &:hover {
    filter: brightness(0.9);
  }
}
.bg-gradient-theme-2, .bg-gradient-theme-5 {
  background: linear-gradient(270deg, $theme-5 10%, $theme-2 90%);
  &:hover {
    filter: brightness(0.9);
  }
}

footer {
  .social--icons {
    @extend .bg-theme-4, .mr-1;
  }
  h2 {
    font-size: 2.5rem !important;
  }
  a {
    @extend .text-white;
    @extend .text-decoration-none;
    &:hover {
      @extend .text-theme-2;
    }
  }
}

.stretched-link::after {
  z-index: 999;
}

.watermark {
  opacity: 1 !important;
}

.highlight-banners-wrapper {
  .highlight-section {
    background: $theme-3;
    position: absolute;
    width: 100%;
    height: 165px;
    bottom: -135px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 0;
    object-fit: cover;
    margin-bottom: 100px;
  }

  .highlight-card-top-img {
    width: 100%;
    height: 100% !important;
    position: relative;
    transition: height .8s;
    object-fit: cover;
  }

  .highlight-card-body {
    @extend .p-3;
    transition: all .8s;
    @extend .bottom-0;
    width: 100%;
    @extend .position-absolute;
    background-color: white;
  }

  .highlight-read-on {
    @extend .d-none;
  }

  .highlight-card-hover-grad {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    bottom: 0;
  }

  .highlight-card {
    border-radius: $border-radius;
    display: inline-block;
    outline: 0;
    @extend .overflow-hidden;
    z-index: 1;
    height: 100%;
    width: 100%;
    position: relative;

    highlight-card-body {
      transition: all .5s ease-in;
      @extend .position-absolute;
    }
    h2 ,h3 {
      color: $dark !important;
    }

    .standard-hidden {
      display: none;
    }

    &:hover, &.hovered {
      .highlight-card-top-img {
        height: 100% !important;
      }

      .highlight-card-body {
        transform: translateY(-45px);
        z-index: 2;
        background: none;

        h2, h3, a, .standard-hidden {
          color: white !important;
          display: unset;
        }
        .text-dark {
          display: none;
        }
      }

      .highlight-card-hover-grad {
        background: $theme-1;
        opacity: .87;
      }

      .highlight-read-on {
        display: block;
      }
    }
  }
}

.flavor-shape-footer {
  margin-top: -40px;
}

.flavour-stripe {
  @extend .position-absolute;
  @extend .left-0;
  @extend .bottom-0;
  max-width: 380px !important;
  margin-bottom: 10px;
}

.border-right--separator {
  @include media-breakpoint-up(md) {
    border-right: solid 2px $primary;
  }
}

.hero-general-cta {
  @include media-breakpoint-up(md) {
    margin-top: 2.3em;
  }
}

.cta--bottom {
  .btn {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}

.btn-secondary, .btn-theme-2 {
  @extend .text-white;
}

.photo-banner {
  @extend .object-fit-cover;
  @extend .mx-auto;
  @extend .w-100;
}

.hero--banner {
  margin-top: -122px;

  .photo-banner {
    @include media-breakpoint-down(sm) {
      min-height: 200px;
      max-height: 350px;
    }
  }
}

.general--banner {
  margin-top: -122px;

  .photo-banner {
    min-height: 200px;
    @include media-breakpoint-down(sm) {
      min-height: 100px;
    }
  }
}

.menu--main {
  @include media-breakpoint-down(md) {
    position: sticky;
    z-index: 99999;
    top: 0;
    background: white;
  }
}

#main-content, main {

  table {
    @extend .table;
  }
}

.rental-scheduler {
  @extend .w-100;
}

hr {
  border-top: solid 1px #2c2c2c;
}

.breadcrumb--bar {
  background-color: $lightgray;
  border-radius: $border-radius;

  a {
    text-decoration: none;
    @extend .text-dark;
  }
}

.part-category-nav {
  .h3 {
    @extend .h2;
  }
  .list-group-item {
    background: none;
  }

  a {
    text-decoration: none;
    @extend .text-dark;
  }
  .list-group-item-action:hover {
    background-color: $lightgray;
  }
}

.ticket-list-item--inner {
  .card-title {
    @extend .text-dark;
    @extend .h5;
  }
  .card-subtitle {
    font-weight: 400;
    @extend .text-primary;
    @extend .mt-0;
  }
  .img-wrapper {
    img {
      @extend .rounded;
    }
  }
  background: none;
  @extend .border-0;
  .ticket-list-item--inner-spacing {
    @extend .px-0;
    @extend .pt-3;
    .btn {
      @extend .btn-block;
    }
  }
}

.bold {
  font-weight: 600 !important;
}

.mobile-hamburger-color {
  @extend .text-dark;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  @extend .bg-dark;
}

.hamburger--squeeze {
  @extend .pr-1;
}

.wrapper {
  overflow: unset !important;
}

.menu--main {
  @include media-breakpoint-down(md) {
    border-bottom: solid 1px $theme-1;
  }
}

.sidebar {
  @extend .pt-3;
  margin-top: -20px;
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  @extend .z-1;
  border-radius: 0 $border-radius $border-radius 0;
  h2 {
    @extend .text-white;
  }

  .invalid-feedback, .was-validated {
    color: $theme-3;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 14rem;
    margin-bottom: -14rem;
  }

  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }

  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }

    .list-group-item {
      &.active {
        background: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }
  }
}

.suggestion-slider {
  .owl-dots {
    @extend .text-center;

    .owl-dot {
      @extend .text-white;
      border: solid white;
      @extend .m-1;
      width: 12px;
      height: 12px;
      border-radius: 10px;

      &.active {
        background: white;
      }
    }
  }

  .review--img {
    aspect-ratio: 1 !important;
    object-fit: cover;
    width: 90px !important;
    border: solid 2px white;
    border-radius: 60px !important;
    @include media-breakpoint-down(md) {
      width: 50px !important;
    }
  }

  .owl-stage-outer {
    padding-left: 40px;
    @include media-breakpoint-down(md) {
      padding-left: 26px;
    }
  }

  .owl-nav {
    @extend .w-100;
    top: 45%;
    transform: translateY(-50%);
    @extend .position-absolute;

    .prev, .next {
      @extend .btn-link;
      @extend .text-white;
      @extend .border-0;
      background: none;
      @extend .text-decoration-none;
      font-size: 2em;
      @include media-breakpoint-down(md) {
        font-size: 1.3em;
      }
    }

    .next {
      transform: translateX(110%);
      @extend .right-0;
      @extend .position-absolute;
      margin-top: 4px;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }
}

.bg-gradient-1-light {
  @include media-breakpoint-up(lg) {
    background: rgb(255, 213, 237);
    background: linear-gradient(180deg, rgba(255, 213, 237, 1) 19%, rgba(255, 213, 237, 0) 78%);
    height: 300px;
  }
  z-index: -10;
}

#main-content {
  table {
    @extend .table;
    thead {
      @extend .bg-light;
    }
  }
}

.img--silvester {
  @extend .z-2;
  top: 200px;
  height: 200px;

  @include media-breakpoint-up(xxxl) {
    height: 300px;
  }
  @include media-breakpoint-down(xl) {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 200px;
    right: 0 !important;
  }
  @include media-breakpoint-down(sm) {
    top: 100px;
    height: 150px;
  }
}

.btn-video-header {
  @extend .position-absolute;
  @extend .btn-light;
  @extend .border-0;
  background-color: rgba(173, 173, 173, 0.46);
  top: 50%;
  left: 36%;
}

.homeherophoto {
  @extend .rounded;
}

.img-circle {
  border-radius: 100px;
}

.quick-navigation--banner {
  @extend .my-4;
}

.timeline {
  .title {
    font-size: 2rem !important;
  }
}

