
.main-timeline5 {
  overflow: hidden;
  position: relative
}

.main-timeline5 .timeline {
  position: relative;
  margin-top: -128px
}

.main-timeline5 .timeline:first-child {
  margin-top: 0
}

.main-timeline5 .timeline-icon, .main-timeline5 .year {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.main-timeline5 .timeline:after, .main-timeline5 .timeline:before {
  content: "";
  display: block;
  width: 100%;
  clear: both
}

.main-timeline5 .timeline:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2
}

.main-timeline5 .timeline-icon {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  border: 25px solid transparent;
  border-top-color: $theme-6;
  border-right-color: $theme-6;
  z-index: 1;
  transform: rotate(45deg)
}

.main-timeline5 .year {
  display: block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .4);
  font-size: 30px;
  font-weight: 700;
  color: $theme-6;
  text-align: center;
  transform: rotate(-45deg)
}

.timeline-content {
  @extend .z-2;
}

.main-timeline5 .timeline-content {
  width: 35%;
  height: 213px;
  float: right;
  background: $theme-6;
  font-size: 0.9em;
  @extend .text-white;
  padding: 15px 20px;
  margin: 50px 0;
  z-index: 1;
  position: relative
}

.main-timeline5 .timeline-content:before {
  content: "";
  width: 20%;
  height: 15px;
  background: $theme-6;
  position: absolute;
  top: 50%;
  left: -20%;
  z-index: -1;
  transform: translateY(-50%)
}

.main-timeline5 .title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 10px
}

.main-timeline5 .description {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  margin: 0
}

.main-timeline5 .timeline:nth-child(2n) .timeline-icon {
  transform: rotate(-135deg);
  border-top-color: $theme-3;
  border-right-color: $theme-3
}

.main-timeline5 .timeline:nth-child(2n) .year {
  transform: rotate(135deg);
  color: $theme-3
}

.main-timeline5 .timeline:nth-child(2n) .timeline-content {
  float: left
}

.main-timeline5 .timeline:nth-child(2n) .timeline-content:before {
  left: auto;
  right: -20%
}

.main-timeline5 .timeline:nth-child(2n) .timeline-content, .main-timeline5 .timeline:nth-child(2n) .timeline-content:before {
  background: $theme-3;
  @extend .text-white;
}

.main-timeline5 .timeline:nth-child(3n) .timeline-icon {
  border-top-color: $theme-5;
  border-right-color: $theme-5
}

.main-timeline5 .timeline:nth-child(3n) .year {
  color: $theme-5
}
.main-timeline5 .timeline:nth-child(3n) .title {
  @extend .text-dark;
}

.main-timeline5 .timeline:nth-child(3n) .timeline-content, .main-timeline5 .timeline:nth-child(3n) .timeline-content:before {
  background: $theme-5;
  @extend .text-dark;
}

.main-timeline5 .timeline:nth-child(4n) .timeline-icon {
  border-top-color: $theme-1;
  border-right-color: $theme-1
}

.main-timeline5 .timeline:nth-child(4n) .year {
  color: $theme-1
}

.main-timeline5 .timeline:nth-child(4n) .timeline-content, .main-timeline5 .timeline:nth-child(4n) .timeline-content:before {
  background: $theme-1;
  @extend .text-white;
}

.main-timeline5 .timeline:nth-child(5n) .timeline-icon {
  border-top-color: $theme-4;
  border-right-color: $theme-4
}

.main-timeline5 .timeline:nth-child(5n) .year {
  color: $theme-4
}

.main-timeline5 .timeline:nth-child(5n) .timeline-content, .main-timeline5 .timeline:nth-child(5n) .timeline-content:before {
  background: $theme-4;
  @extend .text-white;
}

.main-timeline5 .timeline:nth-child(6n) .timeline-icon {
  border-top-color: $theme-2;
  border-right-color: $theme-2
}

.main-timeline5 .timeline:nth-child(6n) .year {
  color: $theme-2
}
.main-timeline5 .timeline:nth-child(6n) .title {
  @extend .text-white;
}

.main-timeline5 .timeline:nth-child(6n) .timeline-content, .main-timeline5 .timeline:nth-child(6n) .timeline-content:before {
  background: $theme-2;
  @extend .text-white;
}

.main-timeline5 .timeline:nth-child(6n) .title,
.main-timeline5 .timeline:nth-child(6n) .timeline-content {
  color: white !important;
}

.main-timeline5 .timeline:nth-child(7n) .timeline-icon {
  border-top-color: $theme-8;
  border-right-color: $theme-8
}

.main-timeline5 .timeline:nth-child(7n) .year {
  color: $theme-8
}

.main-timeline5 .timeline:nth-child(7n) .timeline-content, .main-timeline5 .timeline:nth-child(7n) .timeline-content:before {
  background: $theme-8;
  @extend .text-white;
}

@include media-breakpoint-down(lg) {
  .timeline-content {
    height: 238px !important
  }
  .main-timeline5 .timeline {
    margin-top: -153px
  }
  .main-timeline5 .timeline-content:before {
    left: -18%
  }
  .main-timeline5 .timeline:nth-child(2n) .timeline-content:before {
    right: -18%
  }
}

@include media-breakpoint-down(md) {
  .timeline-content {
    height: 272px !important
  }
  .main-timeline5 .timeline {
    margin-top: -187px
  }
  .main-timeline5 .timeline-content:before {
    left: -2%
  }
  .main-timeline5 .timeline:nth-child(2n) .timeline-content:before {
    right: -2%
  }
}

@include media-breakpoint-down(sm) {
  .timeline-content {
    height: unset !important
  }
  .main-timeline5 .timeline {
    margin-top: 0;
    overflow: hidden
  }
  .main-timeline5 .timeline:before, .main-timeline5 .timeline:nth-child(2n):before {
    box-shadow: none
  }
  .main-timeline5 .timeline-icon, .main-timeline5 .timeline:nth-child(2n) .timeline-icon {
    margin-top: -30px;
    margin-bottom: 20px;
    position: relative;
    transform: rotate(135deg)
  }
  .main-timeline5 .timeline:nth-child(2n) .year, .main-timeline5 .year {
    transform: rotate(-135deg)
  }
  .main-timeline5 .timeline-content, .main-timeline5 .timeline:nth-child(2n) .timeline-content {
    width: 100%;
    float: none;
    border-radius: 0 0 20px 20px;
    text-align: center;
    padding: 25px 20px;
    margin: 0 auto
  }
  .main-timeline5 .timeline-content:before, .main-timeline5 .timeline:nth-child(2n) .timeline-content:before {
    width: 15px;
    height: 25px;
    position: absolute;
    top: -22px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0)
  }
}
