.whatsapp-btn {
  margin: 0 0 20px 0 !important;
  padding: 10px !important;
  position: fixed !important;
  z-index: 9999 !important;
  bottom: 0 !important;
  text-align: center !important;
  border-radius: 25px;
  visibility: visible;
  transition: none !important;
  background-color: #4dc247;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 40%);
  right: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
}

.whatsapp-btn:hover, .whatsapp-btn:focus {
  text-decoration: none;
  background-color: #3e9f3a;;
  color: #fff;
}

.whatsapp-button-icon {
  font-size: 2rem;
}
